import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      if ('appId' in params) {
        this.setNickelledAppId(params.appId);
      } else {
        this.setNickelledAppId('dummy');
      }
    });
  }

  private setNickelledAppId(appId: string) {
    const raw = localStorage.getItem('ni-user-data');
    let extraData = {};

    if (raw) {
      try {
        extraData = JSON.parse(raw);
      } catch (err) {
        extraData = {};
      }
    }

    (window as any).NickelledLaunchers.setUser({
      appId: appId ?? 'dummy',
      ...extraData,
    });
  }

  ngOnInit() {}
}
